import { useMeQuery } from "@/api/auth";
import { mySupportersQuery } from "@/api/me";
import { auth } from "@/api/utils";
import { useHasUnreads } from "@/hooks/useHasUnreads";
import { IconBuildingStoreFilled, IconMessage2Filled, IconVideoFilled } from "@/icons";
import { NewLabel } from "@/pages/(app)/+route";
import { config } from "@common/utils/config";
import { Box, Separator } from "@givenwell/components";
import { CSS, keyframes, styled } from "@givenwell/stitches";
import { colors, easing } from "@givenwell/theme";
import * as BaseDialog from "@radix-ui/react-dialog";
import {
  IconBuildingStore,
  IconCameraDollar,
  IconHeart,
  IconHeartFilled,
  IconHelp,
  IconHistory,
  IconLogout,
  IconMessage2,
  IconSettings,
  IconSpeakerphone,
  IconVideo,
} from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { createLink, Link, LinkComponent } from "@tanstack/react-router";
import { ComponentPropsWithoutRef, forwardRef, MouseEventHandler, ReactNode } from "react";

export function ModalDesktopSidebar({ open, onOpenChange }: { open: boolean; onOpenChange: (open: boolean) => void }) {
  const onClose = () => onOpenChange(false);

  return (
    <BaseDialog.Root open={open} onOpenChange={onOpenChange}>
      <BaseDialog.Portal>
        <Overlay />
        <Content>
          <DesktopSidebarContent onClose={onClose} />
        </Content>
      </BaseDialog.Portal>
    </BaseDialog.Root>
  );
}

export function PersistentDesktopSidebar() {
  return (
    <PersistentSidebarContent>
      <DesktopSidebarContent onClose={() => {}} />
    </PersistentSidebarContent>
  );
}

function DesktopSidebarContent({ onClose }: { onClose: () => void }) {
  const hasUnreads = useHasUnreads();
  const { data: supporterData } = useQuery(mySupportersQuery());
  const me = useMeQuery();
  return (
    <>
      <NavigationMenuLink
        to="/marketplace"
        onClick={onClose}
        icon={<IconBuildingStore strokeWidth={1.5} />}
        activeIcon={<IconBuildingStoreFilled />}
      >
        Marketplace
      </NavigationMenuLink>
      <NavigationMenuLink
        to="/messages"
        onClick={onClose}
        icon={<IconMessage2 strokeWidth={1.5} />}
        activeIcon={<IconMessage2Filled />}
        showBadge={hasUnreads}
      >
        Messages
      </NavigationMenuLink>
      <NavigationMenuLink to="/purchases" onClick={onClose} icon={<IconHistory strokeWidth={1.5} />}>
        Purchases
      </NavigationMenuLink>
      {config.features.claims && (
        <NavigationMenuLink to="/claims" onClick={onClose} icon={<IconCameraDollar strokeWidth={1.5} />}>
          Claims
          <NewLabel inline />
        </NavigationMenuLink>
      )}
      {!!supporterData?.supporters.length && (
        <NavigationMenuLink
          to="/resources"
          onClick={onClose}
          icon={<IconVideo strokeWidth={1.5} />}
          activeIcon={<IconVideoFilled />}
        >
          Resources
        </NavigationMenuLink>
      )}
      <NavigationMenuLink
        to="/favourites"
        onClick={onClose}
        icon={<IconHeart strokeWidth={1.5} />}
        activeIcon={<IconHeartFilled strokeWidth={1.5} />}
      >
        Favourites
      </NavigationMenuLink>
      <Box css={{ px: 8 }}>
        <Separator />
      </Box>
      <NavigationMenuLink
        to="/nominate"
        search={{ from: location.href, email: me.data?.email }}
        icon={<IconSpeakerphone strokeWidth={1.5} />}
      >
        Nominate
      </NavigationMenuLink>
      <NavigationMenuA href="mailto:support@givenwell.com" icon={<IconHelp strokeWidth={1.5} />}>
        Contact Us
      </NavigationMenuA>
      <Box css={{ flex: "1 0 0px" }} />
      <NavigationMenuLink to="/settings" onClick={onClose} icon={<IconSettings strokeWidth={1.5} />}>
        Settings
      </NavigationMenuLink>
      <NavigationMenuLink
        to="/"
        params={true}
        search={true}
        onClick={e => {
          e.preventDefault();
          auth.logout();
        }}
        icon={<IconLogout strokeWidth={1.5} />}
      >
        Sign out
      </NavigationMenuLink>
    </>
  );
}

const fadeIn = keyframes({
  from: { opacity: 0, backdropFilter: "blur(0px)" },
  to: { opacity: 1, backdropFilter: "blur(0px)" },
});

const fadeOut = keyframes({
  from: { opacity: 1, backdropFilter: "blur(0px)" },
  to: { opacity: 0, backdropFilter: "blur(0px)" },
});

const slideInFromLeft = keyframes({
  from: { transform: "translateX(-100%)" },
  to: { transform: "translateX(0)" },
});

const slideOutToLeft = keyframes({
  from: { transform: "translateX(0)" },
  to: { transform: "translateX(-100%)" },
});

const Overlay = styled(BaseDialog.Overlay, {
  backgroundColor: colors.blue900 + "80",
  position: "fixed",
  inset: 0,
  "&[data-state=open]": {
    animation: `${fadeIn()} 500ms cubic-bezier(.2,0,0,1) forwards`,
  },
  "&[data-state=closed]": {
    animation: `${fadeOut()} 400ms cubic-bezier(.2,0,0,1) forwards`,
  },
  zIndex: 999,
});

const PersistentSidebarContent = styled("div", {
  position: "fixed",
  bottom: 0,
  w: 220,
  padding: 16,
  pb: "max(16px, var(--safe-area-inset-bottom))",
  // backgroundColor: colors.white,
  color: colors.gray800,
  zIndex: 1000,
  flexDirection: "column",
  gap: 8,

  left: 0,
  top: "calc(77px + var(--safe-area-inset-top))",

  display: "none",
  "@lg": {
    display: "flex",
  },
});

const Content = styled(BaseDialog.Content, {
  position: "fixed",
  bottom: 0,
  w: "calc(100% - 72px)",
  maxW: 220,
  padding: 16,
  pb: "max(16px, var(--safe-area-inset-bottom))",
  backgroundColor: colors.white,
  color: colors.gray800,
  zIndex: 1000,
  display: "flex",
  flexDirection: "column",
  gap: 8,

  left: 0,
  top: "calc(77px + var(--safe-area-inset-top))",
  "&[data-state=open]": {
    animation: `${slideInFromLeft()} 500ms cubic-bezier(.2,0,0,1) forwards`,
  },
  "&[data-state=closed]": {
    animation: `${slideOutToLeft()} 400ms cubic-bezier(.2,0,0,1) forwards`,
  },
});

type NavigationMenuLinkProps = ComponentPropsWithoutRef<"a"> & {
  css?: CSS;
  icon?: ReactNode;
  activeIcon?: ReactNode;
  onClick?: MouseEventHandler<"a">;
  showBadge?: boolean;
};

export const NavigationMenuLink = createLink(
  forwardRef(function NavigationMenuLink({
    css,
    children,
    icon,
    activeIcon,
    showBadge,
    ...props
  }: NavigationMenuLinkProps) {
    return (
      <StyledA {...(props as any)} css={css}>
        {icon && (
          <IconBox>
            <ActiveIcon>{activeIcon || icon}</ActiveIcon>
            <InactiveIcon>{icon}</InactiveIcon>
            {showBadge && <RedDotBadge />}
          </IconBox>
        )}
        {children}
      </StyledA>
    );
  }),
);

function RedDotBadge() {
  return (
    <Box
      css={{
        position: "absolute",
        top: 0,
        right: 0,
        rounded: 10,
        bg: colors.red500,
        boxShadow: "0 0 0 2px #fff",
        minW: 10,
        h: 10,
      }}
    />
  );
}

type NavigationMenuAProps = ComponentPropsWithoutRef<"a"> & {
  css?: CSS;
  icon?: ReactNode;
};

export function NavigationMenuA({ css, children, icon, ...props }: NavigationMenuAProps) {
  return (
    <StyledA {...props} css={css}>
      {icon && <IconBox>{icon}</IconBox>}
      {children}
    </StyledA>
  );
}

const linkCSS: CSS = {
  height: 40,
  display: "flex",
  alignItems: "center",
  px: (40 - 24) / 2,
  gap: (40 - 24) / 2,

  color: colors.gray600,
  fontScale: "sm",
  weight: 400,
  rounded: 8,
  svg: {
    color: colors.gray500,
  },
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.05)",
  },
  transition: `background-color 0.2s ${easing.standard}`,

  "&.active": {
    weight: 500,
    bg: colors.blue0,
    color: colors.blue800,
    svg: {
      color: colors.blue800,
    },
  },
};

const StyledLink = styled(Link, linkCSS) as unknown as LinkComponent<"a">;
const StyledA = styled("a", linkCSS);

const IconBox = styled("span", {
  display: "flex",
  items: "center",
  justify: "center",
  position: "relative",
  size: 24,

  color: colors.gray500,
  ".active > &": {
    color: colors.blue800,
  },
});

const ActiveIcon = styled("span", {
  display: "none",
  "[aria-current=page] &": {
    display: "block",
  },
});
const InactiveIcon = styled("span", {
  "[aria-current=page] &": {
    display: "none",
  },
});
